import React from "react";
import Layout from "../components/layout/layout";
import { container } from "../styles/guiaDeUso.module.scss";

const Eula = () => {
  return (
    <Layout>
      <div className={container}>
        <h3>ACUERDO DE LICENCIA DE SOFTWARE DE USUARIO FINAL</h3>
        <br />
        <br />
        <p>
          Este Acuerdo de Licencia de Usuario Final (en adelante, “EULA”) regula
          la relación entre usted, en calidad de Usuario (en adelante, "Usted"
          y/o el "Usuario") y Petki Labs, S.L con domicilio en Sant Cugat del
          Vallès, Calle Ramon Escanyola número 20, 08197 Barcelona, y correo
          electrónico a efectos de notificaciones team@kolyy.com (en adelante,
          la "Sociedad", “Kolyy”, "Nosotros", "Nuestro" y "Nos") en relación con
          la “Aplicación Kolyy” (descrita en la Cláusula 2) y cualquier otra
          aplicación o servicio web de Kolyy que contenga un enlace a este EULA
          (en adelante, la "App").
        </p>
        <br />
        <br />
        <p>
          Este EULA establece los términos y condiciones que son de aplicación
          para descargar, instalar, acceder y/o utilizar la App, ya sea desde un
          ordenador, a través de nuestro sitio web https://www.kolyy.com (en
          adelante, el "Sitio Web") o un dispositivo móvil a través de mercados
          como Apple App Store o Google Play Store (las “Plataformas”). Usted
          afirma que es mayor de 18 años, o que cuenta con el consentimiento
          legal de sus padres o tutores, y que es plenamente capaz y competente
          para aceptar los términos, condiciones, obligaciones, afirmaciones,
          declaraciones y garantías establecidas en este EULA, y para acatar y
          cumplir este EULA. Si usted es menor de 18 años o no cuenta con el
          consentimiento de sus padres o tutores, no podrá utilizar ni acceder a
          la App.
        </p>
        <br />
        <br />
        <p>
          Es posible que, de vez en cuando, tengamos que modificar este EULA. Si
          realizamos una modificación de este EULA, le informaremos por correo
          electrónico a la última dirección de correo electrónico que nos haya
          facilitado en su cuenta de usuario de la App o bien por medio de
          notificación en la Plataforma. Si no se opone a los términos y
          condiciones modificados en el plazo de treinta (30) días tras recibir
          la comunicación o tras la publicación de los nuevos términos y
          condiciones en la Plataforma, la modificación de los términos y
          condiciones se considerarán aceptados y entrarán en vigor en ese
          momento. En caso de oposición, deberá enviar comunicación a la
          dirección o correo electrónico de la sociedad dentro de treinta (30)
          días desde la notificación, entendiéndose terminado el EULA a la
          finalización de dicho plazo.
        </p>
        <br />
        <br />
        <p>
          ESTE ES UN ACUERDO DE LICENCIA DE SOFTWARE SOBRE LOS DERECHOS Y
          OBLIGACIONES DEL USUARIO FINAL RESPECTO DE LA APP; NO ES UN CONTRATO
          DE VENTA DE LA APP. La Sociedad seguirá siendo propietaria de la App y
          de todo su contenido, así como de todas las copias que se produzcan
          del mismo, con independencia del soporte. El presente EULA en ningún
          caso constituye ningún título o propiedad en la App a favor del
          Usuario.
        </p>
        <br />
        <br />
        <h6>1. Descripciones.</h6>
        <br />
        <p>
          “Aplicación Kolyy”: Aplicación de software descargable para
          dispositivos móviles titularidad de Kolyy que permite el acceso a los
          servicios de Suscripción contratados por el Cliente en el marco del
          plan de subscripción seleccionado. Sus funcionalidades, funcionalidad
          y operatividad se describen en el Sitio Web y en las Plataformas,
          accesible al Usuario al momento de realizar la descarga.
        </p>
        <br />
        <p>
          “Collar Inteligente”: Collar inteligente para mascotas que incorpora
          un localizador GPS radioeléctrico de Kolyy, el cual está ajustado a
          los servicios que se ofrecen bajo Suscripción. Para que las
          funcionalidades del collar sean operativas, el Cliente deberá
          contratar Suscripción de pago.
        </p>
        <br />
        <p>
          “Servicios” o “Suscripción”: Servicio de pago que, conectado al Collar
          Inteligente, permite, según los servicios incluidos en la suscripción
          contratada: i) averiguar y seguir la ubicación de su mascota
          (“Seguimiento en línea”; {"ii)"} diagnosticar el estado de forma de su
          mascota; y/o, {"iii)"} cualquier otro servicio que pudiere ofrecerse
          al momento de contratar la Suscripción. Antes de contratar la
          Suscripción, el cliente deberá escoger las características que desee
          incluir en su Suscripción. Las características del Plan de Suscripción
          se describen en la Página Web y al momento de realizar la contratación
          a través de la Aplicación Kolyy, como se describe abajo. El Plan de
          suscripción puede ser por periodo mensual, trimestral o anual, a
          elección del Usuario. Cada Collar Inteligente debe tener asociada una
          Suscripción.
        </p>
        <br />
        <p>
          “Usuario” / “Cliente”: Parte contratante del Servicio y/o Productos
          ofrecidos por Kolyy. En el ámbito del Servicio se hará referencia
          generalmente a «Usuario» y, en el ámbito de la Tienda en línea y venta
          de Productos se hará referencia generalmente a «Cliente». «Usuario» y
          «Cliente» también se denominarán juntos como «Parte contratante».
        </p>
        <br />
        <br />
        <h6>2. Datos personales.</h6>
        <br />
        <p>
          2.1. Usted confirma que, como parte del proceso de descarga,
          instalación, creación de una Cuenta de Usuario y uso de la App, ha
          leído y aceptado nuestra Política de Privacidad. Si no está de acuerdo
          con nuestra Política de Privacidad, no debe descargar, instalar,
          acceder o utilizar nuestra App.
        </p>
        <br />
        <p>
          2.2. La Sociedad, como responsable del tratamiento de los datos
          personales que podamos recoger a través de la App y del Collar
          Inteligente, garantiza el estricto cumplimiento de la normativa
          vigente en materia de protección de datos personales del Usuario.
        </p>
        <br />
        <p>
          Al instalar y usar la App, el Usuario acepta que ésta compile y
          recolecte información personal necesaria para el correcto
          funcionamiento de la App y su Suscripción, en particular, para
          gestionar su cuenta Kolyy y su relación con la comunidad; gestionar la
          Suscripción; recibir comunicaciones relacionadas con la Suscripción en
          su caso contratada en primer y segundo plano; recibir comunicaciones
          sobre los eventos de tu calendario, envío de información comercial y/o
          prospección comercial; y, en general, la mejora de los Servicios.
        </p>
        <br />
        <p>
          2.3. Asimismo, el Usuario conoce y acepta que la información
          facilitada por el Usuario y aquella recopilada por el Collar
          Inteligente serán tratadas por la Sociedad con la finalidad de
          localizar a su mascota, pudiendo compartir su nombre y teléfono con
          otros miembros de la comunidad si se activa la función “perros
          perdidos” del Servicio (en caso de que esté disponible) o al escanear
          el código QR del collar; y, elaborar diagnósticos de su mascota.
          Mientras el Usuario use la App, la Sociedad podrá recopilar
          información de posición, ubicación y hora vinculada a Usted desde el
          dispositivo donde tenga instalada la App.{" "}
        </p>
        <br />
        <p>
          2.4. La base legal para el tratamiento de sus datos personales es la
          prestación del Servicio contratado por el Usuario, su consentimiento e
          interés legítimo.
        </p>
        <br />
        <p>
          2.5. No compartiremos los datos con terceros, excepto cuando sea
          legalmente necesario. Aunque no se trata de una transferencia de
          datos, podemos compartir los datos personales con nuestros proveedores
          de servicios cuando sea necesario y bajo los acuerdos necesarios para
          garantizar la protección de sus datos.
        </p>
        <br />
        <p>
          2.6. En cualquier momento podrá ejercer sus derechos de acceso,
          rectificación, supresión, limitación del tratamiento, portabilidad de
          datos y oposición poniéndose en contacto con nosotros en
          team@kolyy.com.
        </p>
        <br />
        <p>
          2.7. Usted no está obligado a compartir los datos personales que se le
          soliciten en ningún momento; sin embargo, si decide no compartir la
          información, en algunos casos no podremos proporcionarle la totalidad
          de los Servicios ni responder eficazmente a las consultas que pueda
          tener.{" "}
        </p>
        <br />
        <br />
        <h6>3. Instalación de la App y Aceptación del EULA.</h6>
        <br />
        <p>
          3.1. Ud. confirma y acepta que como parte del proceso de descarga,
          instalación y creación de una Cuenta de Usuario, Usted ha leído y
          aceptado los términos y condiciones del EULA. En todo caso, usted
          confirma que acepta y está de acuerdo con los términos de este EULA
          accediendo o utilizando la App. Si no está de acuerdo con este EULA,
          no descargue o use la App.{" "}
        </p>
        <br />
        <p>
          3.2. La App requiere instalación, debe instalar la App en un
          Smartphone o equipo correctamente configurado que cumpla con los
          requisitos para la correcta instalación y posterior uso de la App. La
          información sobre la disponibilidad y la funcionalidad de la App
          estará disponible a través de las Plataformas.{" "}
        </p>
        <br />
        <p>
          3.3. La Sociedad pone la App a disposición de los Usuarios. La
          descarga de la App es gratuita, no obstante, para poder acceder a
          todas las funcionalidades que le ofrece la App, es necesaria la compra
          del Collar Inteligente de la Sociedad y la actuación de la
          Suscripción, en los términos previstos en las Condiciones Generales
          (https://www.kolyy.com/terminos-y-condiciones). En el caso de no
          disponer de alguno de estos dos elementos, el Usuario no podrá hacer
          uso normal de la App y de las funcionalidades incluidas.
        </p>{" "}
        <br />
        <p>
          3.4. El Usuario es el único responsable de la conexión a Internet y de
          los gastos de móvil en los que pueda incurrir al acceder y/o utilizar
          la App y/o nuestros Servicios.
        </p>{" "}
        <br />
        <br />
        <h6>4. Cuenta Usuario & Acceso a la App.</h6>
        <br />
        <p>
          4.1. Para poder contratar y hacer uso del Servicio, el Usuario deberá
          registrarse en la App y crear una Cuenta de Usuario (en adelante
          "Cuenta de Usuario"). Para crear la Cuenta de Usuario, el Usuario
          deberá descargar e instalar la App a través de alguna de las
          Plataformas. Su Cuenta de Usuario es para su uso exclusivo y personal
          y no podrá transferirla a ninguna otra persona.
        </p>
        <br />
        <p>
          4.2. En el momento del registro, se solicitará al Usuario los datos
          tales como su nombre, apellido, correo electrónico, teléfono,
          dirección de envío y datos bancarios, así como los datos relativos al
          Collar Inteligente tales como el identificador de serie y el país del
          Collar, y los datos de su mascota tales como nombre, raza, edad, sexo,
          si se encuentra esterilizado o no, peso, condición corporal y otras,
          con la finalidad del buen uso del Servicio.
        </p>
        <br />
        <p>
          4.3. El Usuario declara y se compromete a: (i) proporcionar
          información precisa, actual y completa en relación con los
          procedimientos de registro, inicio de sesión y/o suscripción en la
          Cuenta de Usuario (en adelante "Datos de Registro"); (ii) no revelar
          la contraseña o el código de identificación; (iii) mantener los Datos
          de Registro y cualquier otra información proporcionada constantemente
          actualizada, completa y precisa.
        </p>
        <br />
        <p>
          4.4. Desde Kolyy no seremos responsables ante usted por cualquier
          pérdida que sufra como resultado de que una persona no autorizada
          acceda a su cuenta o utilice nuestros Servicios, y no aceptamos
          ninguna responsabilidad por cualquier pérdida o daño resultante de
          dicho uso no autorizado, ya sea fraudulento o de otro tipo.
        </p>
        <br />
        <p>
          4.5. Kolyy se reserva el derecho de bloquear de manera temporal o
          definitiva, a su discreción, el acceso del Usuario a su Cuenta en caso
          de sospecha fundada de abuso o uso indebido (en especial, para
          registrar patrones de movimiento de terceras personas) o cuando se
          detectase una vulneración de alguna disposición de las presentes CGC,
          incluida la falta de pago de las tarifas a su vencimiento.
        </p>
        <br />
        <p>
          4.6. De vez en cuando, el acceso a la App puede ser suspendida,
          limitada o interrumpida para permitir el despliegue o la provisión de
          parches, actualizaciones, contenido adicional u otras modificaciones a
          la App (por ejemplo, para mejorar la App en línea, para añadir nuevas
          características o actualizarlas o para resolver errores de software).
          Usted acepta, reconoce y entiende que trabajamos constantemente en el
          desarrollo de la App. En cualquier caso, si decidimos realizar una
          modificación relevante en el uso o acceso a la App, le informaremos de
          los cambios realizados.
        </p>
        <br />
        <p>
          4.7. Haremos todo lo posible para proporcionar un acceso continuo a la
          App y para que las interrupciones o suspensiones sean lo más breves
          posible. Ello, no obstante, la Sociedad no garantiza que la App, la
          información, la ubicación o los servicios de soporte no se
          interrumpirán ni estarán libres de errores, que se corregirán en su
          caso por la Sociedad a la mayor brevedad para garantizar su uso.
        </p>
        <br />
        <p>
          4.8. El Usuario es el único responsable de la conexión a Internet y de
          los gastos de móvil en los que pueda incurrir al acceder y/o utilizar
          la App y/o nuestros Servicios.
        </p>
        <br />
        <p>
          4.9. El Servicio y disponibilidad del mismo se regula en las
          Condiciones Generales de Contratación
          (https://www.kolyy.com/terminos-y-condiciones).
        </p>
        <br />
        <br />
        <h6>5. Contratación y Activación de Suscripción.</h6>
        <br />
        <p>
          5.1. Contratación: la contratación de los Servicios se hará mediante
          el Sitio Web o mediante la App, donde se describen las características
          del Plan de Suscripción y el precio aplicable en el momento de la
          suscripción. A estos efectos, los pagos de los Servicios se realizan
          mediante plataformas de pagos de terceros, por lo que la Sociedad no
          asume ninguna responsabilidad sobre la operatividad, funcionalidad,
          uso, pérdida o robo de datos bancarios que puedan resultar del uso de
          plataformas de pago. Para conocer más sobre el uso de plataformas de
          terceros le recomendamos visitar las Condiciones Generales de la
          Contratación (https://www.kolyy.com/terminos-y-condiciones).{" "}
        </p>{" "}
        <br />
        <p>
          5.2. Activar suscripción a través de la App: Usted queda informado de
          que la Suscripción es de pago, por lo que será necesario que el
          Usuario haya abonado previamente la cuantía correspondiente a su Plan
          de Suscripción para poder hacer uso de la App. La cuantía será la que
          corresponda en cada momento y que será visible para el Usuario en el
          momento de la contratación de la Suscripción.
        </p>{" "}
        <br />
        <br />
        <br />
        <h6>6. Licencia y condiciones de Uso de la App.</h6>
        <br />
        <p>
          6.1. Sujeta al presente EULA, Kolyy le otorga a Usted una licencia de
          uso personal, no exclusiva, gratuita, no asignable, intransferible,
          limitada, revocable, rescindible, y sin derecho de otorgar
          sublicencias (la “Licencia”) para acceder a la App o utilizarla
          mediante dispositivos móviles, tabletas electrónicas, ordenadores y
          otros dispositivos electrónicos de su propiedad o sobre los que tenga
          control (los “Dispositivos”) y según le permitan las normas de uso de
          su operador, las descritas en este EULA y aquellas que le sean de
          aplicación a su dispositivo.{" "}
        </p>
        <br />
        <p>
          6.2. Los derechos que Kolyy le otorga en virtud de la Licencia están
          sujetos a los términos de este EULA, y sólo podrá utilizar la Licencia
          si cumple con todos los términos aplicables.
        </p>
        <br />
        <p>
          6.3. La Licencia entra en vigor en la fecha en que usted acepta este
          EULA, descargándose la App. La Licencia no le otorga ningún título o
          propiedad sobre la App.
        </p>
        <br />
        <p>6.4. Usted se abstiene de llevar a cabo las siguientes:</p>
        <br />
        <p>
          {"a)"} conductas que puedan considerarse razonablemente ilegales,
          perjudiciales, de acoso, difamatorias, calumniosas, obscenas o de
          cualquier otro modo censurables; que sea o pueda considerarse
          razonablemente como invasión de la privacidad de otra persona o que
          infrinja los derechos de privacidad, que incite a la violencia o al
          odio racial o étnico; tampoco podrá usar la App con fines fraudulentos
          o abusivos, comportarse de una manera que vaya en detrimento del
          disfrute de la App, hacer uso de la App en más de un dispositivo a la
          vez (en adelante, todas las conductas enumeradas referidas como
          “Comportamiento Inadecuado”).
        </p>
        <br />
        <p>
          {"b)"} conductas que infrinjan cualquier derecho de propiedad
          intelectual, incluyendo la prohibición de eliminar o alterar cualquier
          aviso o leyenda de marca comercial u otra información sobre derechos
          de propiedad que aparezca en nuestra App, o cualquier otro derecho de
          un tercero; descompilar o realizar ingeniería inversa de la App;
          sublicenciar la App total o parcialmente (en adelante, todas las
          conductas enumeradas referidas como “Infracción Propiedad
          Intelectual”).
        </p>{" "}
        <br />
        <p>
          {"c)"} conductas que consistan en publicidad no solicitada o no
          autorizada, material promocional, correo basura, spam, cartas en
          cadena, esquemas piramidales y otras formas de mensajes promocionales
          o que contenga virus de software o cualquier otro código informático,
          archivos o programas diseñados para interrumpir, destruir o limitar la
          funcionalidad de cualquier software o hardware informático o equipo de
          telecomunicaciones; tampoco podrá distribuir o divulgar información
          sobre el desempeño o análisis de la App (en adelante, todas las
          conductas enumeradas referidas como “Publicidad y archivos
          maliciosos”.
        </p>
        <br />
        <p>
          {"d)"} no podrá desmontar o piratear nuestra App, o eludir o bloquear
          nuestras tecnologías de encriptación o medidas de seguridad o los
          datos que transmitimos, procesamos o almacenamos; crear, desarrollar,
          distribuir o utilizar programas de software no autorizados para
          obtener ventajas en cualquier modalidad de App; causar interferencias
          o interrupciones en nuestra App, en los servidores o en las redes a
          través de las cuales se proporciona nuestra App; tampoco podrá
          recopilar, extraer o recoger cualquier tipo de información sobre
          nuestra App o sobre otros usuarios que lo utilicen, incluyendo, pero
          sin limitarse a ello, información o datos personales; tampoco podrá
          utilizarlo para hacer trampas, planear trampas o ayudar a hacerlas, o
          eludir de otro modo las medidas tecnológicas destinadas a controlar el
          acceso a nuestra App o hacer cualquier otra cosa que una persona
          razonable no consideraría que esté en la buena fe o de estas
          condiciones (en adelante, “Manipulación de la App”).
        </p>
        <br />
        <p>
          6.5. Asimismo, el Usuario no podrá hacer uso de la App de modo que
          infrinja cualquier ley o reglamento aplicable y, en general, de forma
          que se infrinja cualquier Ley o reglamento aplicable o de modo que no
          esté expresamente autorizada en este EULA.
        </p>
        <br />
        <p>
          6.6. Asimismo, el Usuario se compromete a cumplir con los compromisos,
          obligaciones y normas de usuario establecidos en las Condiciones
          Generales de Contratación
          (https://www.kolyy.com/terminos-y-condiciones).{" "}
        </p>
        <br />
        <p>
          6.7. Usted es el único responsable del Contenido que Usted descargue,
          comunique, transmita o ponga a disposición a través de la App, la
          Cuenta de Usuario y/nuestros Servicios. Antes de cargar cualquier
          Contenido de Usuario, le invitamos a revisar cuidadosamente las
          Condiciones Generales de Contratación
          (https://www.kolyy.com/terminos-y-condiciones), la Política de
          Privacidad, el presente EULA y las normas específicas de la App que se
          encuentren en la propia App. La Sociedad tiene derecho a eliminar el
          Contenido del Usuario de nuestras Plataformas inmediatamente, en
          cualquier momento y a su entera discreción si consideramos que
          incumple cualquier parte de estas Condiciones. Sin embargo, la
          Sociedad no vigilará activamente el Contenido del Usuario.
        </p>
        <br />
        <p>
          6.8. En caso de incumplimiento por parte del Usuario de este EULA y/o
          de las Condiciones Generales de Contratación
          (https://www.kolyy.com/terminos-y-condiciones), Kolyy se reserva el
          derecho a su libre discreción y sin necesidad de previo aviso de
          amonestar al Usuario, eliminar contenidos del Usuario, bloquear al
          Usuario de manera temporal o permanente, rescindir la relación
          contractual, eliminar la cuenta del Usuario, retener las cantidades
          abonadas por el Usuario y/o exigir una indemnización por daños y
          perjuicios. Todas ellas serán acumulables y su ejercicio no será
          obstáculo para que Kolyy pueda ejercer otra de esas acciones u otros
          de sus derechos, según aquello dispuesto en las Condiciones Generales
          de Contratación y el presente EULA
          (https://www.kolyy.com/terminos-y-condiciones).
        </p>
        <br />
        <br />
        <h6>7. Propiedad Intelectual.</h6>
        <br />
        <p>
          7.1. El Usuario reconoce que Kolyy es el legítimo titular de las
          marcas, nombres comerciales y demás denominaciones y/o signos
          distintivos la App (en adelante, “Marcas Kolyy”). El Cliente no
          registrará o usará ningún dominio en Internet que contenga cualquier
          marca de Kolyy total o parcialmente o cualquier otro nombre que pueda
          inducir a confusión por su similitud o que de cualquier otro modo
          pudiere lesionar los derechos de propiedad industrial de Kolyy. El
          Usuario no debe utilizar etiquetas meta ni ningún otro tipo de texto
          oculto que utilice algún nombre, marca comercial o nombre de producto
          o de servicio de Kolyy sin el previo consentimiento por escrito de
          Kolyy. Además, el aspecto y el estilo de la App (incluyendo todos los
          encabezados de página, gráficos personalizados, iconos de botón y
          secuencias de comandos) son la marca del servicio, la marca comercial
          y la imagen de marca de Kolyy y no deben copiarse, imitarse o
          utilizarse (en su totalidad o en parte) sin el previo permiso por
          escrito de Kolyy. El contenido de la App, incluyendo sin limitación,
          el texto, software, gráficos, imágenes, sonidos y similares (en
          adelante, el "Contenido"), son también propiedad de la Sociedad
          sujetos a derechos de autor y otros derechos de propiedad intelectual
          bajo la legislación española, europea e internacional.
        </p>
        <br />
        <p>
          7.2. Todos los derechos de autor y cualquier otro derecho de propiedad
          intelectual o industrial, copyrights o cualquier otro derecho de
          cualquier índole relativo los contenidos o de los aspectos del App,
          incluido, pero no limitado a las Marcas Kolyy, el Contenido, el diseño
          de la App, sus códigos fuente, corresponde a Kolyy (“Derechos de
          Propiedad Intelectual”). Dichos Derechos de Propiedad Intelectual
          están protegidos por las leyes en materia de derechos de propiedad
          intelectual y por los tratados internacionales vigentes en España y en
          el mundo y los derechos sobre los mismos quedan reservados
          expresamente. Queda prohibido cualquier uso de los contenidos que no
          esté expresamente autorizado en las Condiciones Generales de
          Contratación (https://www.kolyy.com/terminos-y-condiciones) o en el
          presente EULA.{" "}
        </p>
        <br />
        <p>
          7.3. El Usuario no obtendrá, por virtud del uso que haga la App ningún
          Derecho de Propiedad sobre Intelectual que pertenezca a Kolyy. No debe
          aplicar ingeniería inversa, descompilar ni desmontar la App, excepto y
          solo en la medida que hacerlo sea obligatorio bajo la ley aplicable,
          sin perjuicio de esta limitación o de lo expuesto expresamente en el
          presente EULA.
        </p>
        <br />
        <p>
          7.4. El Usuario no podrá, directa o indirectamente, utilizar, copiar,
          reproducir, desencriptar, escanear electrónicamente, distribuir,
          transmitir, emitir, mostrar, vente, licenciar, explotar, descompilar o
          derivar código fuente de ninguna propiedad intelectual y/o industrial
          titularidad de Kolyy al que tenga acceso en virtud de la App, o
          realizar ingeniería inversa en el diseño y función de la referida
          propiedad intelectual y/o industrial. Si el Usuario descarga o imprime
          una copia del Contenido para su uso personal, deberá conservar todos
          los avisos de derechos de autor y otros avisos de propiedad que
          contenga. Asimismo, el Usuario se obliga a informar de manera rápida y
          eficaz de toda infracción o fundado temor de infracción por parte de
          los Usuarios o de terceros que pudiera afectar a los legítimos
          intereses de Kolyy del que el Usuario pudiera tener conocimiento.
        </p>
        <br />
        <p>
          7.5. Sin perjuicio de lo dispuesto, no se podrá reproducir ni archivar
          ningún extremo de los contenidos de la App en otro sitio web ni
          tampoco se podrá introducir en otro sistema de archivo y búsqueda de
          información o de prestación de servicios sin el consentimiento previo
          y por escrito de Kolyy.
        </p>
        <br />
        <p>
          7.6. Toda utilización de la App que se realice sin autorización previa
          por escrito y que no estuviera autorizada en el presente EULA queda
          expresamente prohibida. Dicha utilización no autorizada podría
          asimismo contravenir las leyes aplicables, a título ejemplificativo,
          leyes en materia de derechos de autor, sobre la protección de la marca
          y otras normativas en materia de comunicación o de protección de datos
          con carácter personal. Nos reservamos el derecho a suspender aquellas
          cuentas de Usuarios que consideremos que hayan vulnerado la ley o los
          reglamentos aplicables.
        </p>
        <br />
        <p>
          7.7. Usted acepta y reconoce que, aparte de la licencia que se le
          concede en virtud de este EULA, no tiene ninguna propiedad ni ningún
          derecho sobre la App o cualquiera de nuestros Servicios.{" "}
        </p>
        <br />
        <p>
          7.8. El Contenido de la App se proporciona al Usuario "TAL CUAL" para
          su información y uso personal de la App. No puede ser utilizado con
          fines comerciales.
        </p>
        <br />
        <br />
        <h6>8. Contenido, links y marketing de terceros.</h6>
        <br />
        <p>
          8.1. La App puede descargarse y los pagos de la Suscripción y demás
          compras realizadas a través de la App pueden realizarse desde una de
          las Plataformas, que están gestionadas y son propiedad de terceros
          ajenos a la Sociedad. Las Plataformas pueden tener sus propios
          términos y condiciones y política de privacidad (“Términos de
          Plataforma”) y le puede requerir para que Ud. acepte los Términos de
          Plataforma para registrarse en la misma. La Sociedad no se hace
          responsable de examinar o evaluar dichos Términos de Plataforma y
          usted acepta utilizarlos bajo su propia responsabilidad. Si tiene
          alguna pregunta, duda o queja sobre un servicio de terceros, póngase
          en contacto con el personal de apoyo o de contacto de ese servicio de
          terceros.{" "}
        </p>
        <br />
        <p>
          8.2. La App puede contener enlaces, interfaces de acceso y otras
          conexiones a sitios web y aplicaciones de terceros. Dichos servicios
          de terceros se proporcionan únicamente para su comodidad, y no somos
          responsables de respaldar el contenido de los servicios de terceros.
          El uso de los servicios de terceros puede requerir que usted acepte
          condiciones adicionales. No somos responsables de examinar o evaluar,
          y no garantizamos las ofertas de servicios de terceros, y usted acepta
          utilizarlos bajo su propia responsabilidad. Si tiene alguna pregunta,
          duda o queja sobre un servicio de terceros, póngase en contacto con el
          personal de apoyo o de contacto de ese servicio de terceros. Podemos
          permitirle iniciar sesión en la App utilizando servicios de terceros.
          Al iniciar la sesión mediante un servicio de terceros o al utilizar de
          otro modo los servicios de terceros, usted permite que ese servicio de
          terceros acceda a la información relacionada con su cuenta en ese
          servicio de terceros, y el servicio de terceros podrá acceder a la
          información relativa a sus acciones en la App.
        </p>
        <br />
        <br />
        <h6>9. Duración y terminación.</h6>
        <br />
        <p>
          9.1. El presente EULA entra en vigor desde el momento en que descargue
          la App, y permanecerá en vigor hasta que sea rescindido o sustituido
          por un nuevo acuerdo, o, si no se produce ninguno de los hechos
          anteriores, mientras usted siga utilizando la App.{" "}
        </p>
        <br />
        <p>
          9.2. El presente EULA pueden ser rescindido por cualesquiera de las
          siguientes razones:{" "}
        </p>
        <br />
        <p>
          {"a)"} En caso de incumplimiento material de cualquiera de cualquiera
          de los términos establecidos en el presente EULA por parte del
          Usuario, la Sociedad tendrá derecho a bloquearle el acceso, de forma
          temporal o permanente, a su Cuenta de Usuario de la App en cualquier
          momento, con o sin previo aviso.{" "}
        </p>
        <br />
        <p>
          {"b)"} En caso de que la App y/o los Servicios sean interrumpidos y/o
          terminados por cualquier razón, la Sociedad tendrá derecho a terminar
          esta Suscripción en cualquier momento con treinta (30) días de aviso
          previo.{" "}
        </p>
        <br />
        <p>
          {"c)"} En caso de que el Usuario elimine su Cuenta de Usuario y/o
          desinstale la App, por cualquier motivo, este EULA quedará resuelto en
          el momento en que se complete el proceso de desinstalación.{" "}
        </p>
        <br />
        <p>
          {"d)"} En caso de que el Usuario cancele el Servicio de conformidad
          con lo dispuesto en las Condiciones Generales de Contratación
          (https://www.kolyy.com/terminos-y-condiciones).
        </p>
        <br />
        <p>
          {"e)"} Por los motivos de cancelación y terminación del Servicio
          previstos en las Condiciones Generales de Contratación
          (https://www.kolyy.com/terminos-y-condiciones).{" "}
        </p>
        <br />
        <p>
          {"f)"} En caso de que ambas partes estén de acuerdo en rescindir este
          EULA.
        </p>
        <br />
        <p>
          9.3. Usted reconoce que tras la rescisión del EULA por cualquier
          motivo, su Licencia quedará automáticamente anulada, Usted ya no podrá
          acceder y/o utilizar la App y/o el Servicio, podrá perder el acceso a
          su Cuenta de Usuario y a cualquier Contenido de Usuario, datos y/o
          información previamente almacenada y/o asociada a su Cuenta de Usuario
          y cualquier derecho que haya podido tener con el acceso a la App, que
          se perderá, y usted reconoce que no tiene derecho a ningún reembolso
          por cualquier cantidad que haya sido pagada en su Cuenta antes de
          cualquier terminación de estos, sin perjuicio de lo dispuesto para la
          Suscripción en las Condiciones Generales de Contratación
          (https://www.kolyy.com/terminos-y-condiciones).{" "}
        </p>
        <br />
        <br />
        <h6>10. Responsabilidades y Daños y Perjuicios.</h6>
        <br />
        <p>10.1.	Usted es el único responsable de proveer el hardware y el software necesarios para acceder y usar la App, incluyendo el Collar Inteligente y la contratación del Plan de Suscripción, así como de obtener el acceso a Internet y abonar los gastos de conexión asociados para acceder y utilizar la App.</p>
        <br />
        <p>10.2.	En la medida permitida por ley, la Sociedad garantiza que la App se suministra "tal cual", de acuerdo con los estándares comerciales ampliamente reconocidos. En la medida permitida por ley, Kolyy no declara ni garantiza que (i) el funcionamiento o el uso de la App sea oportuno, ininterrumpido o libre de errores (no obstante, la Sociedad hará lo posible por informarle de cualquier cuestión relacionada con la App en un plazo razonable); o (ii) que la calidad de la App satisfaga las necesidades y expectativas del Usuario. En estos casos, el Usuario no podrá hacer valer ningún derecho a una indemnización por daños y perjuicios y/o de garantía por fallos sin culpabilidad.</p>
        <br />
        <p>10.3.	Si usted accede a la App dentro de un país del espacio Económico Europeo, Suiza, Reino Unido o, en general, donde la Sociedad desarrolle su actividad comercial, estará protegido por la garantía legal según la ley aplicable. En caso de que la App sea disconforme con lo dispuesto en este EULA, Usted tendrá derecho a pedir su conformidad, y en caso de que no pueda ser puesto en conformidad, Usted sólo tendrá derecho a rescindir el EULA, dando por terminado su acceso a la App de conformidad con lo dispuesto en la cláusula 9 del EULA, salvo que la Ley estipule lo contrario. Siendo la App de carácter gratuito, en ningún caso tendrá derecho al reembolso de ninguna cuantía por su falta de conformidad. </p>
        <br />
        <p>10.4.	EXCEPTO EN CASO DE NEGLIGENCIA GRAVE O CONDUCTA INTENCIONADAMENTE ILÍCITA Y EN LA MEDIDA EN QUE LO PERMITA LA LEGISLACIÓN APLICABLE, KOLYY NO SERÁ RESPONSABLE DE NINGÚN DAÑO DIRECTO, INDIRECTO, ESPECIAL, RESULTANTE O INCIDENTAL DERIVADO DEL USO DE LA APP; NI DE LOS DAÑOS CAUSADOS POR EL USO INCORRECTO O ILÍCITO DE LA APP POR PARTE DEL USUARIO O DE TERCEROS. EN CASO DE NEGLIGENCIA LEVE, LA RESPONSABILIDAD DE KOLYY SE LIMITARÁ A LOS DAÑOS PERSONALES, CON UNA RESPONSABILIDAD MÁXIMA DE 100 EUROS POR USUARIO.</p>
        <br />
        <p>10.5.	La Sociedad se reserva el derecho de reclamar al Usuario la reparación de los daños derivados de la infracción de este EULA por el Usuario. El Usuario se compromete a defender, indemnizar y eximir de responsabilidad a Kolyy, de cualquier reclamación, daño, obligación, pérdida, responsabilidad, coste o deuda y gastos (incluidos, entre otros, los honorarios de los abogados) relacionados con el uso que usted haga de la App y que se deriven de: (i) la violación negligente o intencionada por su parte de cualquiera de los términos de este EULA; o (ii) la violación por su parte de cualquier derecho de terceros, incluidos, entre otros, los derechos de autor, de propiedad o de privacidad. Esta obligación de defensa e indemnización sobrevivirá a este EULA y a su uso de la App.</p>
        <br />
        <br />
        <h6>11. Ley aplicable y jurisdicción. </h6>
        <br />
        <p>11.1.	El presente EULA y cualquier disputa, reclamación u obligación que surja de él o en relación con él o su objeto se regirá por la legislación española.</p>
        <br />
        <p>11.2.	Las partes se someten, a su elección, para la resolución de los conflictos y con renuncia a cualquier otro fuero, a los juzgados y tribunales del domicilio del Usuario.</p>
      </div>
    </Layout>
  );
};

export default Eula;
